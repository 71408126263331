window.addEventListener("load", () => {
  const imageInput = document.querySelector("[data-image-input");
  if (!imageInput) return;

  imageInput.addEventListener("change", () => {
    const imagePreview = document.querySelector("#image-preview-container");
    const file = imageInput.files[0];
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const img = new Image();
      img.src = reader.result;
      imagePreview.innerHTML = "";
      imagePreview.appendChild(img);
    });
    file && reader.readAsDataURL(file);
  });
});
